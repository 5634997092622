import styled from "styled-components";

export const UseCasesImg = styled.img`
  cursor: pointer;
  width: 100%;
  place-self: center;
  
  @media screen and (max-width: 800px){
    margin: 0 0 20px 0;
    width: 90%;
  }
`