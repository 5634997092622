import React from "react";
import { graphql } from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Header from "/src/components/pages/products/Header";
import KeyFeatures from "/src/components/pages/products/KeyFeatures";
import UseCases from "/src/components/pages/products/UseCases";
import Benefits from "/src/components/pages/products/Benefits";
import Functions from "/src/components/pages/products/Functions";
import RelatedContentSection from "../../components/pages/products/RelatedContentSection";

const ProxedoNetworkSecurity = ({ data }) => {
  const { pns } = data;

  return (
    <Layout lang={pns.lang} seo={pns.SEO}>
      <Header
        title_white={pns.product.title_white}
        title_color={pns.product.title_color}
        brand_color="PNS-primary"
        subtitle={pns.product.subtitle}
        description={pns.product.description}
        url={pns.product.url}
        url_download={pns.product.url_download}
        src={pns.product.product_icon.url}
      />
      <KeyFeatures
        big_title={pns.key_features_title}
        sub_title={pns.key_features_subtitle}
        cards={pns.key_features_card}
      />
      <UseCases
        big_title={pns.use_cases_title}
        cases={pns.use_cases}
        button_style="PNS-primary"
      />
      <Benefits
        big_title={pns.benefits_title}
        benefits={pns.benefits_card}
        button={pns.benefits_button}
        button_style="PNS-primary"
      />
      <Functions
        big_title={pns.features_title}
        cards={pns.features_card}
        button_download={pns.features_button_download}
        button_color="PNS-primary"
      />
      <RelatedContentSection
        locale={pns.lang}
        related1_slug={pns.related_content.related1_slug}
        related2_slug={pns.related_content.related2_slug}
        related3_slug={pns.related_content.related3_slug}
        related4_slug={pns.related_content.related4_slug}
      />
    </Layout>
  );
};

export const query = graphql`
  query GetSinglePns($locale: String) {
    pns: strapiPns(lang: { eq: $locale }) {
      lang
      product {
        title_white
        title_color
        subtitle
        description
        product_icon {
          url
        }
        url {
          link
          name
        }
        url_download {
          file {
            url
          }
          name
        }
      }
      key_features_title
      key_features_subtitle
      key_features_card {
        avatar {
          url
        }
        id
        title
        list {
          id
          item
        }
      }
      use_cases_title
      use_cases {
        id
        title
        description
        big_icon {
          url
        }
        button_download {
          file {
            url
          }
          name
        }
        button_url {
          name
          link
        }
        label
        icon
      }
      benefits_title
      benefits_card {
        id
        title
        description
      }
      benefits_button {
        id
        name
        link
      }
      features_title
      features_card {
        id
        title
        avatar {
          url
        }
        List {
          id
          item
        }
      }
      features_button_download {
        file {
          url
        }
        name
      }
      related_content {
        related1_slug
        related2_slug
        related3_slug
      }
      SEO {
        title
        isIndexable
        description
        keywords
        preview {
          url
        }
      }
    }
  }
`;

export default ProxedoNetworkSecurity;
