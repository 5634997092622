import React, { useState } from "react";
import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import Col from "../../styled/grid/Col";
import { Body, Header2, Header3 } from "../../styled/typography/Typography";
import { OnlyDesktop, OnlyMobile } from "../../styled/views/MobileOrDesktop";
import { UseCasesImg } from "../../styled/pages/products/use-cases/UseCasesComponents";
import { ButtonA, ButtonLink } from "../../styled/button/ButtonComponents";
import styled, { css } from "styled-components";

const Tabs = styled.div`
  background-color: #001741;
  border: 1px solid #6b9ac44d;
  display: flex;
  width: 100%;
  grid-column: 1 / -1;
  gap: 16px;
  max-width: 90vw;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    flex-direction: row;
    overflow-x: auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const TabsButton = styled.button`
  border-width: 1px;
  border-style: solid;
  color: white;
  height: 100%;
  min-height: 70px;
  text-align: left;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  white-space: nowrap;
  padding: 4px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  ${({ $active }) => css`
    background-color: ${$active ? "#113e6c" : "#001741"};
    border-color: ${$active ? "#113e6c" : "#6B9AC44D"};
  `};

  @media screen and (max-width: 800px) {
    padding-inline: 16px;
    height: 50px;
  }
`;

const TabsContent = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  gap: 16px;

  & > div {
    flex: 1;
    max-width: 50%;
  }

  & h3 {
    font-size: 24px;
    line-height: 32px;
  }
  & p {
    font-size: 18px;
    line-height: 140%;
  }
  & a {
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: 800px) {
    padding-inline: 16px;

    & > div {
      max-width: 100%;
    }
  }
`;

const FileProtectionIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.05277 16.6673H10.0002V14.2883H12.3792V12.5041H13.5686V15.1293L10.8412 17.8568H4.05277C3.39676 17.8568 2.86328 17.3227 2.86328 16.6673V5.96194C2.86328 5.30654 3.39676 4.77246 4.05277 4.77246H8.8107V5.96194H4.05277V16.6673Z"
        fill="white"
      />
      <path d="M5.24219 8.93604H8.2159V10.1255H5.24219V8.93604Z" fill="white" />
      <path d="M5.24219 11.3149H8.2159V12.5044H5.24219V11.3149Z" fill="white" />
      <path
        d="M15.9474 7.15164C15.9474 5.50836 14.0722 4.91719 13.5696 4.78873C13.0778 4.91719 11.1895 5.51669 11.1895 7.15164V10.1253H15.9474V7.15164ZM10 11.3148V7.15164C10 4.88388 12.2576 3.83357 13.4519 3.59508L13.5685 3.57129L13.685 3.59508C14.8793 3.83357 17.1369 4.88388 17.1369 7.15164V11.3148H10Z"
        fill="#00AEEF"
      />
      <path
        d="M12.9746 5.96191H14.1641V7.1514H15.3536V8.34088H14.1641V9.53037H12.9746V8.34088H11.7852V7.1514H12.9746V5.96191Z"
        fill="#00AEEF"
      />
    </svg>
  );
};

const KeyIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16341 8.30542C8.59685 7.87198 8.59685 7.17005 8.16341 6.73661C7.72998 6.30394 7.02804 6.30394 6.59461 6.73661C6.16117 7.17005 6.16117 7.87198 6.59461 8.30542C7.02804 8.73808 7.72998 8.73808 8.16341 8.30542Z"
        fill="#00AEEF"
      />
      <path
        d="M7.88003 4.53428L4.39337 8.02171L7.42281 11.0504L10.9095 7.56373L7.88003 4.53428ZM14.9746 17.2857L9.16653 11.4761L8.50755 12.1351C7.92759 12.7151 6.91804 12.7151 6.33808 12.1351L3.30787 9.10568C2.70949 8.50654 2.71026 7.53304 3.30787 6.9362L6.79529 3.44955C7.08527 3.15957 7.47038 3 7.88003 3C8.28968 3 8.67479 3.15957 8.96477 3.44955L11.9942 6.47899C12.2842 6.76897 12.4438 7.15407 12.4438 7.56373C12.4438 7.97338 12.2842 8.35849 11.9942 8.64846L11.336 9.30744L12.8043 10.7757L11.7196 11.8597L12.8043 12.9445L13.8891 11.8597L14.9738 12.9445L13.8891 14.03L14.9746 15.1155L16.0593 14.0307L17.144 15.1155L14.9746 17.2857Z"
        fill="white"
      />
    </svg>
  );
};

const MonitorShieldIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9507 4.32129H14.165V5.51177H15.9507V11.4641H11.784V12.6546H15.9507V13.2499H4.04594V12.6546H8.21261V11.4641H4.04594V5.51177H5.83166V4.32129H4.04594C3.73021 4.32129 3.42741 4.44671 3.20415 4.66997C2.98089 4.89323 2.85547 5.19603 2.85547 5.51177V13.2499C2.85547 13.5656 2.98089 13.8684 3.20415 14.0917C3.42741 14.3149 3.73021 14.4403 4.04594 14.4403H9.40309V15.6308H7.02214V16.8213H12.9745V15.6308H10.5936V14.4403H15.9507C16.2664 14.4403 16.5692 14.3149 16.7925 14.0917C17.0158 13.8684 17.1412 13.5656 17.1412 13.2499V5.51177C17.1412 5.19603 17.0158 4.89323 16.7925 4.66997C16.5692 4.44671 16.2664 4.32129 15.9507 4.32129V4.32129Z"
        fill="white"
      />
      <path
        d="M9.99572 11.4641C9.99572 11.4641 12.9719 10.2736 12.9719 8.48793V4.9165H7.01953V8.48793C7.01953 10.2736 9.99572 11.4641 9.99572 11.4641ZM8.21001 7.29746H9.40048V6.10698H10.591V7.29746H11.7814V8.48793H10.591V9.67841H9.40048V8.48793H8.21001V7.29746Z"
        fill="#29AAE1"
      />
    </svg>
  );
};

const FirewallIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4269 9.28557H13.5698V7.14272C13.5698 6.95328 13.4945 6.7716 13.3605 6.63764C13.2266 6.50369 13.0449 6.42843 12.8555 6.42843H12.1412V4.28557C12.1412 4.09613 12.0659 3.91445 11.932 3.7805C11.798 3.64654 11.6163 3.57129 11.4269 3.57129H3.56975C3.38031 3.57129 3.19863 3.64654 3.06468 3.7805C2.93072 3.91445 2.85547 4.09613 2.85547 4.28557V15.7141C2.85547 15.9036 2.93072 16.0853 3.06468 16.2192C3.19863 16.3532 3.38031 16.4284 3.56975 16.4284H16.4269C16.6163 16.4284 16.798 16.3532 16.932 16.2192C17.0659 16.0853 17.1412 15.9036 17.1412 15.7141V9.99986C17.1412 9.81042 17.0659 9.62874 16.932 9.49478C16.798 9.36083 16.6163 9.28557 16.4269 9.28557ZM15.7126 12.1427H12.1412V10.7141H15.7126V12.1427ZM7.85547 12.1427V10.7141H10.7126V12.1427H7.85547ZM4.28404 12.1427V10.7141H6.4269V12.1427H4.28404ZM7.85547 7.857V9.28557H4.28404V7.857H7.85547ZM9.28404 9.28557V7.857H12.1412V9.28557H9.28404ZM10.7126 6.42843H7.85547V4.99986H10.7126V6.42843ZM4.28404 4.99986H6.4269V6.42843H4.28404V4.99986ZM4.28404 13.5713H7.85547V14.9999H4.28404V13.5713ZM9.28404 13.5713H12.1412V14.9999H9.28404V13.5713ZM15.7126 14.9999H13.5698V13.5713H15.7126V14.9999Z"
        fill="white"
      />
      <path
        d="M7.85547 4.99986H10.7126V6.42843H12.1412V4.28557C12.1412 4.09613 12.0659 3.91445 11.932 3.7805C11.798 3.64654 11.6163 3.57129 11.4269 3.57129H7.85547V4.99986Z"
        fill="#00AEEF"
      />
    </svg>
  );
};

const MagnifiedHouseIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1412 16.6308L14.693 14.1759C15.7754 12.8506 16.307 11.16 16.1781 9.45371C16.0491 7.74742 15.2694 6.15594 14.0001 5.00833C12.7309 3.86071 11.0692 3.24473 9.35854 3.28776C7.64793 3.33078 6.01927 4.02951 4.8093 5.23947C3.59933 6.44944 2.9006 8.07811 2.85758 9.78872C2.81456 11.4993 3.43054 13.1611 4.57815 14.4303C5.72576 15.6996 7.31725 16.4793 9.02353 16.6082C10.7298 16.7372 12.4204 16.2055 13.7458 15.1232L16.2006 17.5714L17.1412 16.6308ZM4.19319 9.95999C4.19319 8.90451 4.50617 7.87272 5.09257 6.99512C5.67897 6.11751 6.51243 5.4335 7.48757 5.02959C8.46272 4.62567 9.53573 4.51999 10.5709 4.7259C11.6061 4.93182 12.557 5.44008 13.3034 6.18642C14.0497 6.93276 14.558 7.88366 14.7639 8.91887C14.9698 9.95407 14.8641 11.0271 14.4602 12.0022C14.0563 12.9774 13.3723 13.8108 12.4947 14.3972C11.6171 14.9836 10.5853 15.2966 9.52981 15.2966C8.11445 15.2966 6.75706 14.7344 5.75625 13.7336C4.75544 12.7327 4.19319 11.3754 4.19319 9.95999V9.95999Z"
        fill="white"
      />
      <path
        d="M10.8658 11.9613H8.19744V9.29297L6.86328 10.6271V12.6284C6.86328 12.8053 6.93356 12.975 7.05866 13.1001C7.18377 13.2252 7.35344 13.2954 7.53036 13.2954H11.5328C11.7098 13.2954 11.8794 13.2252 12.0045 13.1001C12.1296 12.975 12.1999 12.8053 12.1999 12.6284V10.6271L10.8658 9.29297V11.9613Z"
        fill="#00AEEF"
      />
      <path
        d="M10.0044 5.48394C9.9424 5.42142 9.86862 5.37179 9.78733 5.33792C9.70604 5.30406 9.61885 5.28662 9.53079 5.28662C9.44272 5.28662 9.35553 5.30406 9.27424 5.33792C9.19295 5.37179 9.11917 5.42142 9.05716 5.48394L5.05469 9.48641L6.00194 10.4337L9.53079 6.89815L13.0596 10.4337L14.0069 9.48641L10.0044 5.48394Z"
        fill="#00AEEF"
      />
    </svg>
  );
};

const PasswordPolicyIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.23642 14.4287H4.04594V4.90486H5.23642V14.4287ZM9.99832 14.4287H6.4269V4.90486H12.3793V9.07152H13.5697V4.30962C13.5697 3.98105 13.3031 3.71438 12.9745 3.71438H3.45071C3.12214 3.71438 2.85547 3.98105 2.85547 4.30962V15.0239C2.85547 15.3525 3.12214 15.6191 3.45071 15.6191H9.99832V14.4287Z"
        fill="white"
      />
      <path
        d="M15.9494 16.8095H12.378V13.8333H15.9494V16.8095ZM14.1637 11.4524C14.8208 11.4524 15.3542 11.9863 15.3542 12.6429H12.9732C12.9732 11.9863 13.5065 11.4524 14.1637 11.4524ZM16.5446 12.6429C16.5446 11.3298 15.4762 10.2619 14.1637 10.2619C12.8512 10.2619 11.7827 11.3298 11.7827 12.6429C11.4536 12.6429 11.1875 12.9095 11.1875 13.2381V17.4048C11.1875 17.7333 11.4536 18 11.7827 18H16.5446C16.8738 18 17.1399 17.7333 17.1399 17.4048V13.2381C17.1399 12.9095 16.8738 12.6429 16.5446 12.6429Z"
        fill="#00AEEF"
      />
      <path
        d="M14.7608 15.0239C14.7608 15.3531 14.4947 15.6191 14.1656 15.6191C13.8364 15.6191 13.5703 15.3531 13.5703 15.0239C13.5703 14.6953 13.8364 14.4287 14.1656 14.4287C14.4947 14.4287 14.7608 14.6953 14.7608 15.0239Z"
        fill="#00AEEF"
      />
    </svg>
  );
};

const TasksIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5698 11.4764V5.52397C13.5698 5.20824 13.4443 4.90544 13.2211 4.68218C12.9978 4.45892 12.695 4.3335 12.3793 4.3335H4.04594C3.73021 4.3335 3.42741 4.45892 3.20415 4.68218C2.98089 4.90544 2.85547 5.20824 2.85547 5.52397L2.85547 11.4764C2.85547 11.7921 2.98089 12.0949 3.20415 12.3181C3.42741 12.5414 3.73021 12.6668 4.04594 12.6668H12.3793C12.695 12.6668 12.9978 12.5414 13.2211 12.3181C13.4443 12.0949 13.5698 11.7921 13.5698 11.4764ZM12.3793 6.71445H8.80785V5.52397H12.3793V6.71445ZM6.4269 6.71445V5.52397H7.61737V6.71445H6.4269ZM5.23642 5.52397V6.71445H4.04594V5.52397H5.23642ZM4.04594 11.4764V7.90492H12.3793V11.4764H4.04594Z"
        fill="white"
      />
      <path
        d="M15.3573 13.262V6.71436H14.1669V13.262H5.23828V14.4525H14.1669C14.4826 14.4525 14.7854 14.327 15.0086 14.1038C15.2319 13.8805 15.3573 13.5777 15.3573 13.262Z"
        fill="#00AEEF"
      />
      <path
        d="M15.952 8.5V15.0476H7.02344V16.2381H15.952C16.2677 16.2381 16.5705 16.1127 16.7938 15.8894C17.0171 15.6662 17.1425 15.3634 17.1425 15.0476V8.5H15.952Z"
        fill="#00AEEF"
      />
    </svg>
  );
};

const icons = {
  file_protection: <FileProtectionIcon />,
  key: <KeyIcon />,
  monitor_shield: <MonitorShieldIcon />,
  firewall: <FirewallIcon />,
  magnified_house: <MagnifiedHouseIcon />,
  password_policy: <PasswordPolicyIcon />,
  tasks: <TasksIcon />,
};

const UseCases = ({ big_title, cases, button_style }) => {
  const [currentCase, setCurrentCase] = useState(0);

  return (
    <SectionLayoutGrid id={"use-cases"} wide customMinHeight="usecases">
      {big_title && (
        <Col>
          <Header2
            color="white"
            marginBottom="small"
            style={{
              fontSize: "40px",
              lineHeight: "130%",
            }}
          >
            {big_title}
          </Header2>
        </Col>
      )}
      <Tabs>
        <TabsContainer>
          {cases.map((item, index) => {
            return (
              <TabsButton
                $active={index === currentCase}
                onClick={() => setCurrentCase(index)}
              >
                {item.icon && icons[item.icon]}
                {item.label}
              </TabsButton>
            );
          })}
        </TabsContainer>
        <TabsContent>
          <Col center>
            <Header3 color="white" marginBottom="small">
              {cases[currentCase].title}
            </Header3>
            <Body color="body-text" style={{ marginBlock: "36px" }}>
              {cases[currentCase].description}
            </Body>
            <ButtonContainer>
              {cases[currentCase].button_download && (
                <ButtonA
                  href={cases[currentCase].button_download.file.url}
                  target="_blank"
                  rel="noreferrer"
                  color={button_style}
                >
                  {cases[currentCase].button_download.name}
                </ButtonA>
              )}
              {cases[currentCase].button_url && (
                <ButtonLink
                  to={cases[currentCase].button_url.link}
                  color="secondary-brand"
                >
                  {cases[currentCase].button_url.name}
                </ButtonLink>
              )}
            </ButtonContainer>
            <OnlyMobile>
              <UseCasesImg
                alt={cases[currentCase].title}
                src={cases[currentCase].big_icon.url}
                className="image-for-modal"
              />
            </OnlyMobile>
          </Col>
          <OnlyDesktop>
            <UseCasesImg
              alt={cases[currentCase].title}
              src={cases[currentCase].big_icon.url}
              className="image-for-modal"
            />
          </OnlyDesktop>
        </TabsContent>
      </Tabs>
    </SectionLayoutGrid>
  );
};

export default UseCases;
